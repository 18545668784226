<div *ngFor="let collection of collectionArray; let i=index;" [ngClass]="{'hide': isDetailPage && collection?.detailPageVisibility == 0 || (collection?.collectionType == CONSTANTS?.DEFAULT_COLLECTION_TYPE?.relatedProducts && collection?.productModels && collection?.productModels.length == 1 && collection?.productModels[0].titleId == newProd?.titleId)}">
  <div class="catHeadingWrapper" [ngClass]="{'boxShadow': isDetailPage}">
    <p class="catHeading">{{collection?.collectionTitleName}}</p>
    <a routerLink="/collections/{{collection?.collectionURL}}" class="gridViewT" *ngIf="collection?.collectionType != CONSTANTS?.DEFAULT_COLLECTION_TYPE?.relatedProducts">View
      All</a>
  </div>
  <div class="mainDiv">
    <div class="gridArrow left" [ngClass]="{'disable': leftValue[i] >= 0  || collection?.productModels?.length <= showCount}"
      (click)="moveRight(container, i)">
      <span class="fa fa-chevron-left"></span>
    </div>
    <div class="productsWrapper">
      <div [style.left.px]="leftValue[i]" class="productList">
        <!-- hide same product(current product in detailPage) from related products collections -->
        <div class="products" *ngFor="let productData of collection?.productModels;" [ngClass]="{'hide': collection?.collectionType == CONSTANTS?.DEFAULT_COLLECTION_TYPE?.relatedProducts && productData?.titleId == newProd?.titleId, 'disabled': !(productData?.preOrder && productData?.releaseDate && productData?.releaseDate > todaysDate) && productData?.outOfStock == true}"
          #container>
          <app-product-tile [tile-data]="productData" style="width: 100%" (addToCartWishlist)="addToWishlist($event)"
            (addToCart)="addToCartProduct($event)"></app-product-tile>
        </div>
      </div>
    </div>
    <div class="gridArrow right" [ngClass]="{'disable': leftValue[i] <= maxLeftValue[i]  || collection?.productModels?.length <= showCount}"
      (click)="moveLeft(container, i)">
      <span class="fa fa-chevron-right"></span>
    </div>
  </div>
</div>