import { Component, OnInit, Inject, PLATFORM_ID, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, RouterEvent, ActivatedRoute, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Constants } from '../../../common/constants/lsnetx.constants';
import { filter } from 'rxjs/operators';
import { LoaderService } from '../../../services/loader.service';
import { CategoryService } from '../../../services/category.service';
import { HomePageService } from '../../../services/home-page.service';
import { CategoryModel } from '../../../common/models/category-model';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit, OnDestroy {
  title = 'app';
  data;//data of dialog
  value;
  master: string;
  isDashboard: boolean = false;
  isPos: boolean = false;
  headerVisibility: boolean = true;
  breadcrumbsVisibility: boolean = true;
  showLnkTopArrow : boolean = false
  googleAnalyticsCode = Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO != undefined ? Constants.VENDOR_DETAILS.onlineStoreSettingDTO.seoSettingsDTO.googleAnalyticsCode : '';
  @ViewChild('accountDashboard',{static: true}) accountDashboard: any;
  catList;
  currMap;
  _routeSubscription;
  _routeSubscription2;
  //category
  categoryList: Array<CategoryModel>

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public loaderService: LoaderService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public categoryService: CategoryService,
    private homePageService: HomePageService

  ) { 
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    });
  }

  ngOnInit() {
    if(isPlatformBrowser(this.platformId)){
      if(window.pageYOffset > 0){
        this.showLnkTopArrow = true;
      }
    }
    Constants.MENU_BAR_NAV = this.accountDashboard;
    this._routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((data: NavigationStart) => {
      if (data.url == '/' || data.url == '/' + Constants.WAR_NAME) {
        this.headerVisibility = true
        this.breadcrumbsVisibility = false
      } else if (data.url.indexOf('/checkout') > -1 || data.url.indexOf('/pos') > -1) {
        this.headerVisibility = false
        this.breadcrumbsVisibility = false
        if (data.url.indexOf('/pos') > -1) {
          this.isPos = true
        }
      } else {
        this.headerVisibility = true
        this.breadcrumbsVisibility = true
      }
    })
    this._routeSubscription2 = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((data: NavigationEnd) => {
      if (isPlatformBrowser(this.platformId)) {
        // if (location.href.includes('blog') || location.href.includes('about-us') || location.href.includes('contact-us')) {
        //   this.master = "master showBg";
        // } else {
        //   this.master = "master";
        // }
        // if (location.href.includes('order-confirmed') || location.href.includes('order-cancelled')
        //   || location.href.includes('forgot-password') || location.href.includes('track-order')) {
        //   this.isDashboard = true
        // }
      }
    })
    // this.getCategories();
  }

  ngOnDestroy() {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    if (this._routeSubscription2) {
      this._routeSubscription2.unsubscribe();
    }
  }

  currMapHandler(event) {
    this.currMap = event
  }

  catgListHandler(event) {
    this.categoryList = event;
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showLoader();
    }
    if (event instanceof NavigationEnd) {
      this.hideLoader();
    }
    if (event instanceof NavigationCancel) {
      this.hideLoader();
    }
    if (event instanceof NavigationError) {
      this.hideLoader();
    }
  }

  showLoader() {
    this.loaderService.show();
  }

  hideLoader() {
    this.loaderService.hide();
  }
  
  scrollToTop(){
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if(isPlatformBrowser(this.platformId)){
      if(window.pageYOffset == 0){
        this.showLnkTopArrow = false
      }else if(window.pageYOffset > 0){ 
        this.showLnkTopArrow = true
      }
    }
  }

  //categories
  // getCategories() {
  //   this.categoryService.getCategories(Constants.GET_CATEGORY_MODE.ALL, resp => {
  //     if (resp.data != undefined) {
  //       this.categoryList = resp.data;
  //       Constants.CATEGORY_MAP = resp.data;
  //       let childOnlyCats: Array<CategoryModel> = [], parentOnlyCats: Array<CategoryModel> = [];
  //       if (this.categoryList.length > 0) {
  //         this.categoryList.forEach((ele) => {
  //           let parentCat = JSON.parse(JSON.stringify(ele));
  //           parentCat.categoryModels = undefined;
  //           parentOnlyCats.push(parentCat);
  //           if (ele.categoryModels && ele.categoryModels.length > 0) {
  //             let childCats = ele.categoryModels;
  //             childCats.forEach((childEle) => {
  //               childOnlyCats.push(childEle);
  //             })
  //           }
  //         })
  //       }
  //       this.homePageService.allCategoriesBehaviour.next(this.categoryList);
  //       this.homePageService.childCategoriesBehaviour.next(childOnlyCats);
  //       this.homePageService.parentCategoriesBehaviour.next(parentOnlyCats);
  //     } else {
  //       this.categoryList = this.setDummyCategory();
  //     }
  //   })
  // }

  // setDummyCategory() {
  //   let categoryArray: Array<CategoryModel> = new Array<CategoryModel>();
  //   for (let i = 0; i < 2; i++) {
  //     let model: CategoryModel = new CategoryModel();
  //     let catArray: Array<CategoryModel> = new Array<CategoryModel>();
  //     model.categoryId = i + "";
  //     model.categoryURL = "shop" + i;
  //     model.categoryName = "Shop" + i;
  //     for (let j = 0; j < 4; j++) {
  //       let categoryModel: CategoryModel = new CategoryModel();
  //       let subCatArray: Array<CategoryModel> = new Array<CategoryModel>();
  //       categoryModel.categoryId = i + "" + j;
  //       categoryModel.categoryName = "Category" + j;
  //       categoryModel.categoryURL = "shop" + i + "/category" + j;
  //       for (let k = 0; k < 3; k++) {
  //         let subCategory: CategoryModel = new CategoryModel();
  //         subCategory.categoryId = i + "" + j + "" + k;
  //         subCategory.categoryName = "SubCategory" + k;
  //         subCategory.categoryURL = "shop" + i + "/category" + j + "/subCatgeory" + k;
  //         subCatArray.push(subCategory);
  //       }
  //       categoryModel.categoryModels = subCatArray;
  //       catArray.push(categoryModel);
  //     }
  //     model.categoryModels = catArray;
  //     categoryArray.push(model);
  //   }
  //   return categoryArray;
  // }

}
